exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-category-prismic-post-category-uid-js": () => import("./../../../src/pages/blog/category/{prismicPostCategory.uid}.js" /* webpackChunkName: "component---src-pages-blog-category-prismic-post-category-uid-js" */),
  "component---src-pages-blog-prismic-post-uid-js": () => import("./../../../src/pages/blog/{prismicPost.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-post-uid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blogList.js" /* webpackChunkName: "component---src-templates-blog-list-js" */)
}

